function loadMatomo(app, path, debug) {
    if (app === 'somnio') {
        (function () {

            /**
             * part 1: matomo tracking
             */
            const _paq = window._paq = window._paq || [];
            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
            _paq.push(['trackPageView']);
            _paq.push(["setCookieDomain", "*.somn.io"]);
            _paq.push(["setDomains", ["*.somn.io"]]);
            _paq.push(['enableLinkTracking']);
            /*
            disabled as requested by 10x studio
            (function () {
                var u = 'https://somn.matomo.cloud/';
                _paq.push(['setTrackerUrl', u + 'matomo.php']);
                _paq.push(['setSiteId', '1']);
                const d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
                g.async = true;
                g.src = '//cdn.matomo.cloud/somn.matomo.cloud/matomo.js';
                s.parentNode.insertBefore(g, s);
            })();
             */


            /**
             * part 2: matomo tag manager
             */
            const _mtm = window._mtm = window._mtm || [];
            _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
            const d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.async = true;
            g.src = debug ? 'https://cdn.matomo.cloud/somn.matomo.cloud/container_ta3S5DOz_dev_5dc0a8d9ed9a05899d2466fb.js' : 'https://cdn.matomo.cloud/somn.matomo.cloud/container_ta3S5DOz.js';
            s.parentNode.insertBefore(g, s);
        })();
    }
}

